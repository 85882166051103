import * as React from "react"
import {ReactNode} from "react"
import {Media} from "../constants";
import styled from 'styled-components';
import {Root} from "./Root";
import {PageState} from "./PageState";
// @ts-ignore
import SocialImage from "../images/social.png";

export type LayoutProps = {
    className?: string;
    children: ReactNode;
} & PageState;

export function Layout({children, className, ...pageStateProps}: LayoutProps) {
    return (
        <Root className={className}>
            <PageState {...pageStateProps}>
                <img src={SocialImage} style={{width: 0, height: 0, opacity: 0}}/>
                {/*<AppBar/>*/}
                <Main className={'main'}>
                    {children}
                </Main>
                {/*<Footer/>*/}
            </PageState>
        </Root>
    )
}


const Main = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  
  @media (${Media.laptop}) {
      align-items: center;  
      justify-content: center;      
  }
`;



